import {applyMiddleware, createStore, combineReducers} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";

import thunkMiddleware from "redux-thunk";
import {packageSelectorReducer} from "./packageSelector";
import {annotatorReducer} from "./annotator";
import {drawingGuiReducer} from "./drawingGui";
import {drawingCanvasReducer} from "./drawingCanvas";

export const store = createStore(combineReducers({
    packageSelector: packageSelectorReducer,
    annotator: annotatorReducer,
    drawingGui: drawingGuiReducer,
    drawingCanvas: drawingCanvasReducer
}), composeWithDevTools(applyMiddleware(thunkMiddleware)));