import React from 'react';
import Annotator from './Annotator'
import PackageSelector from './PackageSelector'
import Login from './Login'
import PrivateRoute from "./PrivateRoute"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";



class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <PrivateRoute path="/annotator/:package_name">
            <Annotator />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <PackageSelector />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default App;
