import React from 'react';
import './DrawingCanvas.css';
import { loadImage, uploadAnnotation } from './util/RequestHandler'
import cache_controller from "./util/CacheController"
import {connect} from "react-redux";
import {doChangeImage} from "./action/drawingCanvas";


class DrawingCanvas extends React.Component {

    getMousePos(canvas, evt) {
        var rect = canvas.getBoundingClientRect();
        let x = (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width;
        let y = (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height;
        return {
            scaledX: (x - this.state.panX) / this.props.scale,
            scaledY: (y - this.state.panY) / this.props.scale,
            rawX: x,
            rawY: y
        };
    }

    constructor(props){
        super(props)
        this.img_canvas = React.createRef();
        this.draw_canvas = React.createRef();
        this.masked_image_canvas = React.createRef();
        this.mask_data = null;
        this.annotation_changed = false;

        this.state = {
            img_ctx: null,
            draw_ctx: null,
            masked_image_ctx: null,
            mouse_pressed: false,
            button_pressed: null,
            prevPos: {},
            currPos: {},
            keyDownPos: {},
            keyDownCache: {},
            panX: 0,
            panY: 0,
            annotated_pixels: 0
        }
    }

    async componentDidMount() {
        this.setState({
            img_ctx: this.img_canvas.current.getContext('2d'),
            draw_ctx: this.draw_canvas.current.getContext('2d'),
            masked_image_ctx: this.masked_image_canvas.current.getContext('2d')
        });
        this.props.changeImage();
        this.draw_canvas.current.focus();
    }

    clearDrawing() {
        this.state.draw_ctx.clearRect(0, 0, this.props.w, this.props.h);
    }

    isCanvasBlank() {
        const draw_ctx = this.state.draw_ctx;
        
        const pixelBuffer = new Uint32Array(
            draw_ctx.getImageData(0, 0, this.props.w, this.props.h).data.buffer
        );
        
        return !pixelBuffer.some(color => color !== 0);
    }

    isCanvasRendered() {
        const draw_ctx = this.state.draw_ctx;
        try {
            draw_ctx.getImageData(0, 0, this.props.w, this.props.h)
            return true
        } catch {
            return false
        }
    }

    async saveDrawing(image_name, hand_side){
        if (!this.isCanvasRendered() || (this.isCanvasBlank() && !this.annotation_changed)) return;
        const blob = await new Promise(resolve => this.draw_canvas.current.toBlob(resolve));
        uploadAnnotation(this.props.package, image_name, hand_side, blob)
    }

    async saveDrawingLocally(image_name, hand_side){
        if (!this.isCanvasRendered() || (this.isCanvasBlank() && !this.annotation_changed)) return;
        const blob = await new Promise(resolve => this.draw_canvas.current.toBlob(resolve));
        await cache_controller.saveImageLocally(this.props.package, image_name, hand_side, blob)
    }

    saveAnnotationIfNecessary(prev_image) {
        this.state.draw_ctx.globalCompositeOperation = 'source-over';
        if(this.props.hand_side)
        {
            // using function params, because onImageChange can affect props
            this.saveDrawing(prev_image, this.props.hand_side)
        }
        this.clearDrawing()
    }

    storeMaskData() {
        this.state.draw_ctx.drawImage(this.props.mask_img, 0, 0);
        this.mask_data = this.state.draw_ctx.getImageData(0, 0, this.props.w, this.props.h);
        for (let i = 0; i < this.props.w * this.props.h * 4; i += 4) {
            if (this.mask_data.data[i] === 255) {
                this.mask_data.data[i+3] = 0;
            }
        }
        this.state.draw_ctx.clearRect(0, 0, this.props.w, this.props.h);
    }

    drawMaskedImage() {
        this.state.masked_image_ctx.putImageData(this.mask_data, 0, 0);
        this.state.masked_image_ctx.globalCompositeOperation = 'source-in';
        this.state.masked_image_ctx.drawImage(this.props.lut_img, 0, 0);
    }

    async componentDidUpdate(prevProps, prevState) {
        let contextJustInitialized = prevState.draw_ctx === null && this.state.draw_ctx !== null;
        let selectedImageChanged = this.props.image_name !== prevProps.image_name;
        let selectedLutChanged   = this.props.lut !== prevProps.lut;
        let annotationJustLoaded = (contextJustInitialized || !prevProps.annotation_loaded) && this.props.annotation_loaded && this.props.annotation_img !== null;
        let maskJustLoaded  = (contextJustInitialized || prevProps.mask_img === null) && this.props.mask_img !== null;
        let lutJustLoaded = (contextJustInitialized || prevProps.lut_img === null) && this.props.lut_img !== null;

        if (selectedImageChanged) {
            await this.saveAnnotationIfNecessary(prevProps.image_name)
        }
        if (selectedImageChanged || selectedLutChanged) {
            this.props.changeImage();
            return;
        }
        if (maskJustLoaded) {
            this.storeMaskData();
        }
        if (annotationJustLoaded) {
            this.annotation_changed = false;
        }
        if ((annotationJustLoaded && this.props.mask_img !== null) || (maskJustLoaded && this.props.annotation_img !== null)) {
            this.state.draw_ctx.drawImage(this.props.annotation_img, 0, 0);
            this.repaint();
            this.apply_mask()
        }
        if ((lutJustLoaded && this.props.mask_img !== null) || (maskJustLoaded && this.props.lut_img !== null)) {
            this.drawMaskedImage();
            this.repaint();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.scale === 1.0) {
            return {panX: 0, panY: 0};
        }
        return null;
    }

    apply_mask() {
        let pixels = 4 * this.props.w * this.props.h;
        this.state.draw_ctx.globalCompositeOperation = 'source-over';
        let current_drawing = this.state.draw_ctx.getImageData(0, 0, this.props.w, this.props.h);
        let current_drawing_data = current_drawing.data;
    
        this.state.draw_ctx.drawImage(this.props.mask_img, 0, 0);
        let mask_data = this.mask_data.data;

        let mask_size = 0;
        let annotation_size = 0;

        for (let i = 0; i < pixels; i+=4) {
            let inside_mask = (mask_data[i]) === 255;
            let has_drawing = current_drawing_data[i+3] !== 0;
            current_drawing_data[i+3] =  (inside_mask && has_drawing) * 255;

            mask_size += inside_mask;
            annotation_size += (inside_mask && has_drawing);
        }
        this.state.draw_ctx.putImageData(current_drawing, 0, 0);
        this.props.setAnnotationPercentage(mask_size, annotation_size);
    }

    repaint() {
        const w = this.props.w;
        const h = this.props.h;
        const img = this.props.lut_img;
        const img_ctx = this.state.img_ctx;
        const draw_ctx = this.state.draw_ctx;
        const masked_image_ctx = this.state.masked_image_ctx;
        const panX = this.state.panX;
        const panY = this.state.panY;
        const currPos = this.state.currPos;
        const scale = this.props.scale;
        const brush_size = this.props.brush_size;
        const opacity = this.props.opacity;

        if(!img_ctx || !draw_ctx || !masked_image_ctx || !img) return;

        img_ctx.save();
        draw_ctx.save();
        masked_image_ctx.save();
        if(scale > 1.0)
        {
            img_ctx.translate(panX, panY);
            img_ctx.scale(scale, scale);
    
            draw_ctx.translate(panX, panY);
            draw_ctx.scale(scale, scale);

            masked_image_ctx.translate(panX, panY);
            masked_image_ctx.scale(scale, scale);
        }

        img_ctx.drawImage(img, 0, 0, w, h);
        img_ctx.globalAlpha = opacity;
        img_ctx.drawImage(this.draw_canvas.current, 0, 0, w, h);
        img_ctx.globalAlpha = 1.0;
        img_ctx.drawImage(this.masked_image_canvas.current, 0, 0, w, h);

        if(this.props.hand_side && !this.state.button_pressed){
            img_ctx.beginPath();
            img_ctx.strokeStyle = "blue";
            img_ctx.lineWidth = 2;
            img_ctx.arc(currPos.scaledX, currPos.scaledY, brush_size / 2, 0, 2 * Math.PI);
            img_ctx.stroke();
            img_ctx.closePath();
        } else if (this.props.hand_side && this.state.button_pressed === "f"){
            const old_brush_size = this.state.keyDownCache.brush_size;
            const keyDownPos = this.state.keyDownPos;
            img_ctx.beginPath();
            img_ctx.strokeStyle = "blue";
            img_ctx.lineWidth = 2;
            img_ctx.arc(keyDownPos.scaledX, keyDownPos.scaledY, brush_size / 2, 0, 2 * Math.PI);
            img_ctx.stroke();
            img_ctx.strokeStyle = "gray";
            img_ctx.lineWidth = 1;
            img_ctx.arc(keyDownPos.scaledX, keyDownPos.scaledY, old_brush_size / 2, 0, 2 * Math.PI);
            img_ctx.stroke();
            img_ctx.closePath();
        } else if (this.props.hand_side && this.state.button_pressed === "s"){
            let start_angle = 0;
            let delta_angle = 2 * Math.PI / this.props.colors.length;
            const keyDownPos = this.state.keyDownPos;
            const current_color = this.props.current_color;
            for (let i = 0; i < this.props.colors.length; i++, start_angle += delta_angle) {
                img_ctx.beginPath();
                img_ctx.strokeStyle = this.props.colors[i].color;
                img_ctx.lineWidth = 20 / scale * ((i === current_color) + 1);
                img_ctx.arc(keyDownPos.scaledX, keyDownPos.scaledY, 60 / scale, start_angle, start_angle + delta_angle);
                img_ctx.stroke();
                img_ctx.closePath();
            }
        }

        img_ctx.restore();
        draw_ctx.restore();
        masked_image_ctx.restore();
    }


    handleDown() {
        if(this.state.button_pressed)
        {
            return;
        }

        const draw_ctx = this.state.draw_ctx;
        const selected_color = this.props.colors[this.props.current_color].color;
        const currPos = this.state.currPos;
        const brush_size = this.props.brush_size;

        let erase = selected_color === "#ffffff";

        draw_ctx.liecap = "round";

        draw_ctx.globalCompositeOperation = erase ? 'destination-out' : 'source-over';
        draw_ctx.filter = "url(#crisp)";

        draw_ctx.beginPath();
        draw_ctx.fillStyle = selected_color;
        draw_ctx.arc(currPos.scaledX, currPos.scaledY, brush_size / 2, 0, 2 * Math.PI);
        draw_ctx.fill();
        draw_ctx.closePath();
        this.annotation_changed = true;
    }

    getKeydownProperties() {
        const p1 = this.state.keyDownPos;
        const p2 = this.state.currPos;
        let a = p2.rawX - p1.rawX;
        let b = p2.rawY - p1.rawY;
        let angle = Math.atan2(b, a) * 180 / Math.PI;
        let sign = 1;
        if(angle < -90 || angle > 90) {
            sign = -1;
        }
        let dist = Math.sqrt(a*a + b*b);
        return {dist: dist, angle: angle, sign: sign}
    }

    clamp(n, min, max) {
        if(n < min) return min;
        if(n > max) return max;
        return n;
    }

    changeColor() {
        const {angle} = this.getKeydownProperties();
        let angle_remapped = (angle + 360) % 360;
        let delta_angle = 360 / this.props.colors.length;
        let idx = Math.floor(angle_remapped / delta_angle);
        this.props.setCurrentColor(idx);
    }

    changeOpacity() {
        const {dist, sign} = this.getKeydownProperties();
        let o = this.state.keyDownCache.opacity + sign*dist/300;
        o = this.clamp(o, 0, 1);
        this.props.setOpacity(o);
    }

    changeBrushSize() {
        const {dist, sign} = this.getKeydownProperties();
        let b = this.state.keyDownCache.brush_size + sign*dist;
        b = this.clamp(b, 1, 200);
        this.props.setBrushSize(b);
    }

    pan() {
        const prevPos = this.state.prevPos;
        const currPos = this.state.currPos;
        const scale = this.props.scale;

        let panX = this.state.panX;
        let panY = this.state.panY;

        if(prevPos.rawX >= 0 || prevPos.rawY >= 0)
        {
            panX += (currPos.rawX - prevPos.rawX);
            panY += (currPos.rawY - prevPos.rawY);
            if(scale === 1.0)
            {
                panY = panX = 0;
            }
            panX = Math.min(0, panX);
            panY = Math.min(0, panY);
            panX = Math.max(-1*this.props.w*(scale-1), panX);
            panY = Math.max(-1*this.props.h*(scale-1), panY);
            this.setState({panX: panX, panY: panY});
        }
    }

    draw() {
        const draw_ctx = this.state.draw_ctx;
        const selected_color = this.props.colors[this.props.current_color].color;
        const prevPos = this.state.prevPos;
        const currPos = this.state.currPos;
        const brush_size = this.props.brush_size;

        let erase = selected_color === "#ffffff";
        draw_ctx.filter = "url(#crisp)";
        draw_ctx.globalCompositeOperation = erase ? 'destination-out' : 'source-over';
        draw_ctx.beginPath();
        draw_ctx.moveTo(prevPos.scaledX, prevPos.scaledY);
        draw_ctx.lineTo(currPos.scaledX, currPos.scaledY);
        draw_ctx.strokeStyle = selected_color;
        draw_ctx.lineWidth = brush_size;
        draw_ctx.lineCap = "round";
        draw_ctx.lineJoin = "round";
        draw_ctx.stroke();
        draw_ctx.closePath();
        
        this.annotation_changed = true;
    }

    handleMove()
    {
        if(this.state.button_pressed) {
            switch (this.state.button_pressed) {
                case "c":
                    if(!this.state.mouse_pressed) return;
                    this.pan();
                    break;
                case "a":
                    this.changeOpacity();
                    break;
                case "f":
                    this.changeBrushSize();
                    break;
                case "s":
                    this.changeColor();
                    break;
                default:
                    break;
            }
            
        }
        else {
            if(!this.state.mouse_pressed) return;
            this.draw();
        }
    }

    handleWheel(e)
    {
        const currPos = this.state.currPos;
        let scale = this.props.scale;
        let panX = this.state.panX;
        let panY = this.state.panY;

        let direction = (e.deltaY<0) ? -1 : 1;

        let imageX = (currPos.rawX - panX) / scale;
        let imageY = (currPos.rawY - panY) / scale;
        scale = Math.max(1.0, scale + (direction*3 / - 10));
        panX = currPos.rawX-imageX*scale;
        panY = currPos.rawY-imageY*scale;
        if(panX > 0 || panY > 0 || scale === 1.0)
        {
            panY = panX = 0;
        }
        this.setState({ panX: panX, panY: panY});
        this.props.setScale(scale);
    }

    handleClick(res, e) {
        if(!this.props.lut_img || !this.props.mask_img ) return;
        if(res === "move")
        {
            this.handleMove(e);
        }
        else if(res === "down" && this.props.hand_side){
            this.setState({mouse_pressed: true}, this.handleDown);
        }
        else if (res === "up" || res === "out" && this.state.mouse_pressed === true)
        {
            this.setState({mouse_pressed: false});
            this.apply_mask();
            this.saveDrawingLocally(this.props.image_name, this.props.hand_side)
        }
        else if(res === "wheel")
        {
            this.handleWheel(e);
        }
    }

    handleClickReact(res, pos, e) {
        this.draw_canvas.current.focus();
        this.setState((state) => {
            return {prevPos: state.currPos,
                    currPos: this.getMousePos(this.draw_canvas.current, pos)};
          }, () => { this.handleClick(res, pos); });
        //e.preventDefault();
    }

    handleKey(res, e) {
        if(res === "down" && !this.state.button_pressed) {
            this.setState({ button_pressed: e.key, keyDownPos: this.state.currPos, 
                            keyDownCache: {opacity: this.props.opacity, brush_size: this.props.brush_size}});
        }
        else if(res === "up"){
            this.setState({button_pressed: null});
        }
    }

    render() {
            this.repaint();
            let allImagesLoaded = this.props.lut_img !== null && this.props.mask_img != null && this.props.annotation_loaded;
            let patternLoaded = this.props.hand_side !== "--Unknown--";
            let canvasWidth = this.img_canvas.current != null ? this.img_canvas.current.offsetWidth
                            : this.draw_canvas.current != null ? this.draw_canvas.current.offsetWidth: 0;
            let canvasHeight = this.img_canvas.current != null ? this.img_canvas.current.offsetHeight
                            : this.draw_canvas.current != null ? this.draw_canvas.current.offsetHeight : 0;

            return(
                <React.Fragment>
                    <canvas className="pr-3 canvas" width={this.props.w} height={this.props.h} ref={this.img_canvas}></canvas>
                    <canvas className="pr-3 canvas" style={{opacity: 0}} width={this.props.w} height={this.props.h} ref={this.draw_canvas} tabIndex="1"
                            onMouseDown={(e) => this.handleClickReact("down", {clientX: e.clientX, clientY: e.clientY}, e)}
                            onMouseUp={(e) => this.handleClickReact("up", {clientX: e.clientX, clientY: e.clientY}, e)}
                            onMouseOut={(e) => this.handleClickReact("out", {clientX: e.clientX, clientY: e.clientY}, e)}
                            onMouseMove={(e) => this.handleClickReact("move", {clientX: e.clientX, clientY: e.clientY}, e)}
                            onWheel={(e) => this.handleClickReact("wheel", {clientX: e.clientX, clientY: e.clientY, deltaY: e.deltaY}, e)}
                            onKeyDown={(e) => this.handleKey("down", {key: e.key})}
                            onKeyUp={(e) => this.handleKey("up", {key: e.key})}>
                    </canvas>
                    <canvas className="pr-3 canvas" style={{pointerEvents: "none", opacity: 0}} width={this.props.w} height={this.props.h} ref={this.masked_image_canvas}></canvas>
                    {(!allImagesLoaded || !patternLoaded) && <React.Fragment>
                        <div className={"canvas-overlay"} style={{width: canvasWidth - 16, height: canvasHeight}} onClick={(e) => e.stopPropagation()}/>
                        <div className={"canvas-loading-indicator-container"}>
                            <div className={"canvas-loading-indicator"}>
                                Loading...
                            </div>
                        </div>
                    </React.Fragment>}
                    <svg style={{visibility: "hidden", width: 0, height: 0}}>
                        <defs>
                            <filter id="crisp">
                            <feComponentTransfer>
                                <feFuncA type="discrete" tableValues="0,1"></feFuncA>
                            </feComponentTransfer>
                            </filter>
                        </defs>
                    </svg>

                </React.Fragment>
            );
    }
}
/*

*/
function mapStateToProps(state, props) {
    return {
        mask_img: state.drawingCanvas.get("mask_img"),
        lut_img: state.drawingCanvas.get("lut_img"),
        annotation_img: state.drawingCanvas.get("annotation_img"),
        annotation_loaded: state.drawingCanvas.get("annotation_loaded"),
        w: state.drawingCanvas.get("w"),
        h: state.drawingCanvas.get("h"),
    }
}

function mapDispatchToProps(dispatch, props) {
    return {
        changeImage: () => {
            dispatch(doChangeImage(props.package, props.mask, props.lut.get("path"), props.image_name));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawingCanvas);