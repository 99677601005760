import {requestHandler, submitPackage} from "../util/RequestHandler";
import cache_controller from "../util/CacheController";
import {
    PACKAGE_SELECTOR_ERROR,
    PACKAGE_SELECTOR_SET_FILTER, PACKAGE_SELECTOR_SET_SUBMITTING,
    PACKAGE_SELECTOR_UPDATE_PACKAGES
} from "./index";

function packageSelectorUpdatePackages(packages) {
    return {
        type: PACKAGE_SELECTOR_UPDATE_PACKAGES,
        packages
    }
}

function packageSelectorError(error) {
    return {
        type: PACKAGE_SELECTOR_ERROR,
        error
    }
}

export function packageSelectorSetFilter(filter) {
    return {
        type: PACKAGE_SELECTOR_SET_FILTER,
        filter
    }
}

function packageSelectorSetSubmitting(submitting) {
    return {
        type: PACKAGE_SELECTOR_SET_SUBMITTING,
        submitting
    }
}

export function doGetPackages() {
    return (dispatch) => {
        requestHandler.get("/get_packages")
        .then(
            async (result) => {
                for (let i = 0; i < result.data.packages.length; i++) {
                    let unuploaded_count = await cache_controller.countUnuploadedAnnotations(result.data.packages[i].name);
                    result.data.packages[i].unuploaded_count = unuploaded_count;
                }
                dispatch(packageSelectorUpdatePackages(result.data.packages))
            },
            (error) => {
                dispatch(packageSelectorError(error))
            }
        )
    }
}

export function doSubmitPackage(package_obj) {
    return async (dispatch) => {
        dispatch(packageSelectorSetSubmitting(true))
        await submitPackage(package_obj.get("name"), package_obj.get("PackID"));
        dispatch(packageSelectorSetSubmitting(false))
        dispatch(doGetPackages())
    }
}