import cache_controller from "../util/CacheController";
import {loadPatternForTick} from "../util/RequestHandler";
import {
    DRAWING_GUI_SET_ANNOTATION_PERCENTAGE,
    DRAWING_GUI_SET_BRUSH_SIZE,
    DRAWING_GUI_SET_CURRENT_COLOR, DRAWING_GUI_SET_CURRENT_LUT, DRAWING_GUI_SET_HAND_SIDE, DRAWING_GUI_SET_OPACITY,
    DRAWING_GUI_SET_SCALE,
    DRAWING_GUI_UPDATE_HAND_SIDE_AND_PATTERN
} from "./index";

function drawingGuiUpdateHandSideAndPattern(hand_side, pattern) {
    return {
        type: DRAWING_GUI_UPDATE_HAND_SIDE_AND_PATTERN,
        hand_side,
        pattern
    }
}

export function drawingGuiSetHandSide(hand_side) {
    return {
        type: DRAWING_GUI_SET_HAND_SIDE,
        hand_side
    }
}

export function drawingGuiSetScale(scale){
    return {
        type: DRAWING_GUI_SET_SCALE,
        scale
    }
}

export function drawingGuiSetCurrentColor(current_color){
    return {
        type: DRAWING_GUI_SET_CURRENT_COLOR,
        current_color
    }
}

export function drawingGuiSetBrushSize(brush_size){
    return {
        type: DRAWING_GUI_SET_BRUSH_SIZE,
        brush_size
    }
}

export function drawingGuiSetOpacity(opacity){
    return {
        type: DRAWING_GUI_SET_OPACITY,
        opacity
    }
}

export function drawingGuiSetAnnotationPercentage(annotation_percentage){
    return {
        type: DRAWING_GUI_SET_ANNOTATION_PERCENTAGE,
        annotation_percentage
    }
}

export function drawingGuiSetCurrentLut(current_lut){
    return {
        type: DRAWING_GUI_SET_CURRENT_LUT,
        current_lut
    }
}

export function doUpdateHandSideAndPattern(package_name, image) {
    return async (dispatch) => {
        dispatch(drawingGuiUpdateHandSideAndPattern(null, "--Unknown--"))

        let annotation_side = await cache_controller.getAnnotationHandSide(package_name, image.get("name"));
        let tick = image.get("name").split("_", 1)[0];
        let pattern = await loadPatternForTick(tick);

        if(!annotation_side){
            annotation_side = {hand_side: null}
        }
        if(!pattern){
            pattern = {pattern: "--Unknown--"}
        }

        dispatch(drawingGuiUpdateHandSideAndPattern(annotation_side.hand_side, pattern.pattern))
    }
}