import {fromJS} from "immutable";
import {
    PACKAGE_SELECTOR_ERROR,
    PACKAGE_SELECTOR_SET_FILTER,
    PACKAGE_SELECTOR_SET_SUBMITTING,
    PACKAGE_SELECTOR_UPDATE_PACKAGES
} from "../action";

const defaultState = {
    packages: [],
    error: null,
    filter: "active",
    isLoaded: false,
    selected_img: null,
    submitting: false
}

export function packageSelectorReducer(state = fromJS(defaultState), action) {
    if (action.type === PACKAGE_SELECTOR_UPDATE_PACKAGES) {
        return state.set("packages", fromJS(action.packages))
            .set("selected_img", 0).set("isLoaded", true);
    }
    if (action.type === PACKAGE_SELECTOR_ERROR) {
        return state.set("error", action.error).set("isLoaded", true);
    }
    if (action.type === PACKAGE_SELECTOR_SET_FILTER) {
        return state.set("filter", action.filter);
    }
    if (action.type === PACKAGE_SELECTOR_SET_SUBMITTING) {
        return state.set("submitting", action.submitting)
    }
    return state;
}