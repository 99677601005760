import React from 'react';
import './SideBar.css'
import DrawingCanvas from './DrawingCanvas'
import cache_controller from "./util/CacheController"
import { loadPatternForTick } from "./util/RequestHandler"
import {annotatorSetSelectedImage, doGetPackage} from "./action/annotator";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    doChangeImage,
    doUpdateHandSideAndPattern,
    drawingGuiSetAnnotationPercentage,
    drawingGuiSetBrushSize,
    drawingGuiSetCurrentColor,
    drawingGuiSetCurrentLut,
    drawingGuiSetHandSide,
    drawingGuiSetOpacity,
    drawingGuiSetScale
} from "./action/drawingGui";
let classNames = require('classnames');

const colors = [
    {name: "Washed", color: "#00ff00"},
    {name : "Not Washed", color : "#ff0000"},
    {name : "Jewellery", color : "#ffff00"},
    {name : "Nails Not Washed", color : "#ff00ff"},
    {name : "Nails Washed", color : "#00ffff"},
    {name : "Erase", color : "#ffffff"}
];

const scale_factor = 10;
const opacity_factor = 100;

class DrawingGui extends React.Component {

    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        this.props.updateHandSideAndPattern(this.props.package, this.props.image);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.image.get("name") !== this.props.image.get("name")) {
            this.props.updateHandSideAndPattern(this.props.package, this.props.image);
        }
    }

    render(){
        const {brush_size, opacity, current_color, scale, current_lut, annotation_percentage, image, hand_side, pattern} = this.props;
        const {setCurrentColor, setCurrentLut, setBrushSize, setOpacity, setScale, setHandSide,
            setAnnotationPercentage, updateHandSideAndPattern} = this.props;

        const color_list = colors.map((color, idx) => {
            return(
                <li className="list-group-item" key={idx} style={{background : color.color}}
                    onClick={() => {setCurrentColor(idx)}}>{color.name}</li>
            );
        });

        const lut_list = image.get("lut").map((lut, idx) => {
            let classes = classNames({
                "list-group-item": true,
                "text-truncate": true,
                "active" : idx === current_lut
            });
            return (
                <li key={idx} className={classes} onClick={ () => setCurrentLut(idx) }> {lut.get("name")}
                </li>
            );
        });

        let annotation_progress_classes = classNames({
            "progress-bar": true,
            "bg-success": annotation_percentage >= 88,
            "bg-warning": annotation_percentage > 70 && annotation_percentage < 88,
            "bg-danger": annotation_percentage <= 70
        });

        return (
        <React.Fragment>
        <div className="col-8">
            <DrawingCanvas 
                colors={colors}
                current_color={current_color}
                setCurrentColor={setCurrentColor}
                brush_size={brush_size}
                setBrushSize={setBrushSize}
                opacity={opacity}
                setOpacity={setOpacity}
                scale={scale}
                setScale={setScale}
                image_name={this.props.image.get("name")}
                lut={this.props.image.getIn(["lut", current_lut])}
                mask={this.props.image.get("mask")}
                package={this.props.package}
                hand_side={hand_side}
                setAnnotationPercentage={setAnnotationPercentage}
                onImageChange={() => updateHandSideAndPattern(this.props.package, this.props.image)}
            />
        </div>
        <div className="col-2">
            <div className="row">
                Pattern: {pattern}
            </div>
            <div className="row">
                Annotated
                <div className="progress w-100">
                    <div className={annotation_progress_classes} role="progressbar" 
                    style={{width: annotation_percentage + "%"}} 
                    aria-valuenow={annotation_percentage} 
                    aria-valuemin="0" aria-valuemax="100">{annotation_percentage}%</div>
                </div>
            </div>
            <div className="row">
                Zoom
                <input type="range" autoComplete="off" min="10" max="50" value={scale * scale_factor} className="slider" id="zoom"
                onChange={(e) => {setScale(e.target.value/scale_factor)}}></input>
            </div>
            <div className="row">
                Opacity
                <input  type="range" min="0" max={1.0 * opacity_factor} value={opacity * opacity_factor} className="slider" id="opacity"
                        onChange={(e) => {setOpacity(e.target.value/opacity_factor)}}></input>
            </div>
            <div className="row">
                Brush size
                <input type="range" min="1" max="200" value={brush_size} className="slider" id="brush"
                        onChange={(e) => {setBrushSize(e.target.value)}}></input>
            </div>
            <div className="row">
                Object To Annotate
            </div>
            <ul className="row list-group">
                {color_list}
            </ul>
            <div className="row">
                <div className="col">
                    <input type="radio" id="palm" name="hand_side" value="palm"
                    checked={hand_side === "palm"}
                    onChange={(e) => {setHandSide(e.target.value)}}
                    ></input>
                    <label htmlFor="palm">Palm</label>
                </div>
                <div className="col">
                    <input type="radio" id="back" name="hand_side" value="back"
                    checked={hand_side === "back"}
                    onChange={(e) => {setHandSide(e.target.value)}}
                    ></input>
                    <label htmlFor="back">Back of Hand</label>
                </div>
            </div>
            <div className="row">
                Lookup Table
            </div>
            <div className="row">
                <div className="sidebar-sticky-lut">
                    <ul className="list-group nav felx-column">
                        {lut_list}
                    </ul>
                </div>
            </div>
        </div>
        </React.Fragment>
        );
    }

}

function mapStateToProps(state, props) {
    let selected_img = state.annotator.get("selected_img");
    return {
        package: props.match.params.package_name,
        image: state.annotator.getIn(["images", selected_img]),
        brush_size: state.drawingGui.get("brush_size"),
        opacity: state.drawingGui.get("opacity"),
        current_color: state.drawingGui.get("current_color"),
        scale: state.drawingGui.get("scale"),
        current_lut: state.drawingGui.get("current_lut"),
        hand_side: state.drawingGui.get("hand_side"),
        annotation_percentage: state.drawingGui.get("annotation_percentage"),
        pattern: state.drawingGui.get("pattern")
    }
}

function mapDispatchToProps(dispatch, props) {
    return {
        updateHandSideAndPattern: (package_name, image) => {
            dispatch(doUpdateHandSideAndPattern(package_name, image));
        },
        setScale: (scale) => {
            dispatch(drawingGuiSetScale(scale));
        },
        setCurrentColor: (current_color) => {
            dispatch(drawingGuiSetCurrentColor(current_color));
        },
        setBrushSize: (brush_size) => {
            dispatch(drawingGuiSetBrushSize(brush_size));
        },
        setOpacity: (opacity) => {
            dispatch(drawingGuiSetOpacity(opacity));
        },
        setAnnotationPercentage: (mask_size, annotation_size) => {
            let annotation_percentage = Math.round(100 * (annotation_size/mask_size));
            dispatch(drawingGuiSetAnnotationPercentage(annotation_percentage));
        },
        setCurrentLut: (current_lut) => {
            dispatch(drawingGuiSetCurrentLut(current_lut));
        },
        setHandSide: (hand_side) => {
            dispatch(drawingGuiSetHandSide(hand_side));
        }
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(DrawingGui));