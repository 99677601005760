import {requestHandler} from "../util/RequestHandler";
import {ANNOTATOR_ERROR, ANNOTATOR_SET_SELECTED_IMAGE, ANNOTATOR_UPDATE_PACKAGE} from "./index";

function annotatorUpdatePackage(images) {
    return {
        type: ANNOTATOR_UPDATE_PACKAGE,
        images
    }
}

function annotatorError(error) {
    return {
        type: ANNOTATOR_ERROR,
        error
    }
}

export function annotatorSetSelectedImage(selected_img) {
    return {
        type: ANNOTATOR_SET_SELECTED_IMAGE,
        selected_img
    }
}

export function doGetPackage(packageName) {
    return (dispatch) => {
        requestHandler.get("/get_package/" + packageName)
            .then(
                (result) => {
                    dispatch(annotatorUpdatePackage(result.data.images))
                },
                (error) => {
                    dispatch(annotatorError(error))
                }
            )
    }
}