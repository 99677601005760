import {fromJS} from "immutable";
import {
    DRAWING_GUI_SET_ANNOTATION_PERCENTAGE,
    DRAWING_GUI_SET_BRUSH_SIZE,
    DRAWING_GUI_SET_CURRENT_COLOR, DRAWING_GUI_SET_CURRENT_LUT, DRAWING_GUI_SET_HAND_SIDE, DRAWING_GUI_SET_OPACITY,
    DRAWING_GUI_SET_SCALE,
    DRAWING_GUI_UPDATE_HAND_SIDE_AND_PATTERN,
} from "../action";

const defaultState = {
    brush_size: 20,
    opacity: 0.5,
    current_color: 0,
    scale: 1.0,
    current_lut: 0,
    hand_side: null,
    annotation_percentage: 0,
    pattern: "--Unknown--"
}

export function drawingGuiReducer(state = fromJS(defaultState), action) {
    if (action.type === DRAWING_GUI_UPDATE_HAND_SIDE_AND_PATTERN) {
        return state.set("hand_side", action.hand_side).set("pattern", action.pattern);
    }
    if (action.type === DRAWING_GUI_SET_HAND_SIDE) {
        return state.set("hand_side", action.hand_side);
    }
    if (action.type === DRAWING_GUI_SET_SCALE) {
        return state.set("scale", action.scale)
    }
    if (action.type === DRAWING_GUI_SET_CURRENT_COLOR) {
        return state.set("current_color", action.current_color)
    }
    if (action.type === DRAWING_GUI_SET_BRUSH_SIZE) {
        return state.set("brush_size", action.brush_size)
    }
    if (action.type === DRAWING_GUI_SET_OPACITY) {
        return state.set("opacity", action.opacity)
    }
    if (action.type === DRAWING_GUI_SET_ANNOTATION_PERCENTAGE) {
        return state.set("annotation_percentage", action.annotation_percentage)
    }
    if (action.type === DRAWING_GUI_SET_CURRENT_LUT) {
        return state.set("current_lut", action.current_lut)
    }

    return state;
}






