import { requestHandler } from './RequestHandler'

const auth = {
    isAuthenticated: false,
    authenticate(data, cb) {
        requestHandler.post('/login', data).then(
            (response) => {
                auth.isAuthenticated = true;
                cb();
            }, (error) => {console.log(error)}
        );
        
    },
    signout(cb) {
        requestHandler.post('/logout', {}).then(
            (response) => {
                auth.isAuthenticated = false;
                cb();
            }, (error) => {console.log(error)}
        );
    }
};

export default auth;