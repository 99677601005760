import {fromJS} from "immutable";
import {
    DRAWING_CANVAS_UPDATE_ANNOTATION_IMAGE, DRAWING_CANVAS_UPDATE_LUT_IMAGE, DRAWING_CANVAS_UPDATE_MASK_IMAGE,
    DRAWING_CANVAS_UPDATE_PATHS,
} from "../action";

const defaultState = {
    mask_path: null,
    lut_path: null,
    annotation_path: null,
    mask_img: null,
    lut_img: null,
    annotation_img: null,
    annotation_loaded: false,
    w: null,
    h: null
}

export function drawingCanvasReducer(state = fromJS(defaultState), action) {
    if (action.type === DRAWING_CANVAS_UPDATE_PATHS) {
        if (action.mask_path !== state.get("mask_path")) {
            if (state.get("mask_img") !== null) {
                URL.revokeObjectURL(state.getIn(["mask_img", "src"]));
                state = state.set("w", null).set("h", null);
            }
            state = state.set("mask_path", action.mask_path).set("mask_img", null);
        }

        if (action.lut_path !== state.get("lut_path")) {
            if (state.get("lut_path") !== null) {
                URL.revokeObjectURL(state.getIn(["lut_img", "src"]));
            }
            state = state.set("lut_path", action.lut_path).set("lut_img", null);
        }

        if (action.annotation_path !== state.get("annotation_path")) {
            if (state.get("annotation_path") !== null) {
                URL.revokeObjectURL(state.getIn(["annotation_img", "src"]));
            }
            state = state.set("annotation_path", action.annotation_path)
                .set("annotation_img", null).set("annotation_loaded", false);
        }
        return state;
    }

    if (action.type === DRAWING_CANVAS_UPDATE_MASK_IMAGE && action.mask_path === state.get("mask_path")) {
        return state
            .set("mask_img", fromJS(action.mask_img))
            .set("w", action.mask_img.width)
            .set("h", action.mask_img.height);
    }

    if (action.type === DRAWING_CANVAS_UPDATE_LUT_IMAGE && action.lut_path === state.get("lut_path")) {
        return state.set("lut_img", fromJS(action.lut_img));
    }

    if (action.type === DRAWING_CANVAS_UPDATE_ANNOTATION_IMAGE && action.annotation_path === state.get("annotation_path")) {
        let annotation_img = action.annotation_img || null;
        return state.set("annotation_img", fromJS(annotation_img)).set("annotation_loaded", true);
    }

    return state;
}