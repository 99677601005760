import React from 'react';
import './Login.css'
import auth from './util/Auth'
import { withRouter } from "react-router";

class Login extends React.Component {

  constructor(props){
    super(props);
    this.name = React.createRef();
    this.pwd = React.createRef();
  }


  handleSubmit(event){
    let history = this.props.history;
    let { from } = { from: { pathname: "/" } };
    event.preventDefault();
    auth.authenticate({name: this.name.current.value, password: this.pwd.current.value}, () => {
      history.replace(from);
    });
  }

  render() {
    return (
        <form className="form-signin text-center" onSubmit={(e) => {this.handleSubmit(e)}}>
            <img className="mb-4" src="logo192.png" alt="" width="72" height="72"></img>
            <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
            <label htmlFor="inputName" className="sr-only">3 letter short name</label>
            <input id="inputName" className="form-control" placeholder="3 letter short name" required autoFocus ref={this.name} />
            <label htmlFor="inputPassword" className="sr-only">Password</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Password" required ref={this.pwd} />
            <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
       </form>
    )
  }
}

export default withRouter(Login);
