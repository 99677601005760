import LocalImageDB from "./LocalDatabase"
import { requestHandler, loadImage, loadImageFromData } from './RequestHandler'
class CacheController {
    constructor() {
        this.db = new LocalImageDB()
    }

    async saveImageLocally(package_name, image_name, hand_side, image_data){
        await this.db.addImage(package_name, image_name, hand_side, image_data)
    }

    async removeLocalImage(package_name, image_name){
        await this.db.removeImage(package_name, image_name)
    }

    async getAnnotationImage(package_name, image_name){
        let annotation_image;
        let local_annotation = await this.db.getImage(package_name, image_name);
        if(local_annotation){
            annotation_image = await loadImageFromData(local_annotation.blob);
        } else{
            annotation_image = await loadImage("get_annotation_image/" + package_name + "/" + image_name)
        }
        return annotation_image;
    }

    async getAnnotationHandSide(package_name, image_name){
        let local_annotation = await this.db.getImage(package_name, image_name);
        if(local_annotation)
        {
            return local_annotation;
        } else {
            try {
                let response = await requestHandler.get("/get_annotation_side/" + package_name + "/" + image_name);
                return response.data;
            } catch (error) {
                return null;
            }
        }
    }

    async countUnuploadedAnnotations(package_name){
        let count = await this.db.countImages(package_name);
        return count;
    }
}

let cache_controller = new CacheController();


export default cache_controller;

