import Dexie from 'dexie';
class LocalImageDB {
    constructor() {
        this.db = new Dexie("LocalImageDB");
        this.db.version(1).stores({
            images: "[package_name+img_name], hand_side, blob"
        });
    }
    async addImage(package_name, img_name, hand_side, blob){
        await this.db.images.put({
            package_name: package_name,
            img_name: img_name,
            hand_side: hand_side,
            blob: blob
        });
    }

    async removeImage(package_name, img_name){
        await this.db.images
            .where('[package_name+img_name]')
            .equals([package_name, img_name])
            .delete()
    }

    async getImage(package_name, img_name){
        return await this.db.images
            .where('[package_name+img_name]')
            .equals([package_name, img_name])
            .first()
    }

    async getImages(package_name){
        return await this.db.images
        .where('[package_name+img_name]').between(
            [package_name, Dexie.minKey],
            [package_name, Dexie.maxKey])
        .toArray();
    }

    async countImages(package_name){
        return await this.db.images
        .where('[package_name+img_name]').between(
            [package_name, Dexie.minKey],
            [package_name, Dexie.maxKey])
        .count();
    }
}



export default LocalImageDB;

