import React from 'react';
import {
    Route,
    Redirect,
  } from "react-router-dom";
  import auth from "./util/Auth"
  

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// https://reacttraining.com/react-router/web/example/auth-workflow
function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  export default PrivateRoute;