export const PACKAGE_SELECTOR_UPDATE_PACKAGES = 'PACKAGE_SELECTOR_UPDATE_PACKAGES';
export const PACKAGE_SELECTOR_ERROR = 'PACKAGE_SELECTOR_ERROR';
export const PACKAGE_SELECTOR_SET_FILTER = 'PACKAGE_SELECTOR_SET_FILTER';
export const PACKAGE_SELECTOR_SET_SUBMITTING = 'PACKAGE_SELECTOR_SUBMITTING';
export const ANNOTATOR_UPDATE_PACKAGE = 'ANNOTATOR_UPDATE_PACKAGE';
export const ANNOTATOR_ERROR = 'ANNOTATOR_ERROR';
export const ANNOTATOR_SET_SELECTED_IMAGE = 'ANNOTATOR_SET_SELECTED_IMAGE';
export const DRAWING_GUI_UPDATE_HAND_SIDE_AND_PATTERN = 'DRAWING_GUI_UPDATE_HAND_SIDE_AND_PATTERN';
export const DRAWING_GUI_SET_HAND_SIDE = 'DRAWING_GUI_SET_HAND_SIDE';
export const DRAWING_GUI_SET_SCALE = 'DRAWING_GUI_SET_SCALE';
export const DRAWING_GUI_SET_CURRENT_COLOR = 'DRAWING_GUI_SET_CURRENT_COLOR';
export const DRAWING_GUI_SET_BRUSH_SIZE = 'DRAWING_GUI_SET_BRUSH_SIZE';
export const DRAWING_GUI_SET_OPACITY = 'DRAWING_GUI_SET_OPACITY';
export const DRAWING_GUI_SET_ANNOTATION_PERCENTAGE = 'DRAWING_GUI_SET_ANNOTATION_PERCENTAGE';
export const DRAWING_GUI_SET_CURRENT_LUT = 'DRAWING_GUI_SET_CURRENT_LUT';
export const DRAWING_CANVAS_UPDATE_PATHS = 'DRAWING_CANVAS_UPDATE_IMAGES';
export const DRAWING_CANVAS_UPDATE_MASK_IMAGE = 'DRAWING_CANVAS_UPDATE_MASK_IMAGE';
export const DRAWING_CANVAS_UPDATE_LUT_IMAGE = 'DRAWING_CANVAS_UPDATE_LUT_IMAGE';
export const DRAWING_CANVAS_UPDATE_ANNOTATION_IMAGE = 'DRAWING_CANVAS_UPDATE_ANNOTATION_IMAGE';