import {fromJS} from "immutable";
import {
    ANNOTATOR_ERROR, ANNOTATOR_SET_SELECTED_IMAGE,
    ANNOTATOR_UPDATE_PACKAGE,
} from "../action";

const defaultState = {
    images: [],
    error: null,
    isLoaded: false,
    selected_img: null
}

export function annotatorReducer(state = fromJS(defaultState), action) {
    if (action.type === ANNOTATOR_UPDATE_PACKAGE) {
        return state.set("images", fromJS(action.images)).set("isLoaded", true).set("selected_img", 0);
    }
    if (action.type === ANNOTATOR_ERROR) {
        return state.set("error", action.error).set("isLoaded", true);
    }
    if (action.type === ANNOTATOR_SET_SELECTED_IMAGE) {
        return state.set("selected_img", action.selected_img)
    }
    return state;
}