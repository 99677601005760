import axios from 'axios';
import cache_controller from "./CacheController"
import LocalImageDB from "./LocalDatabase"
const axios_instance = axios.create({
    baseURL: '/api/',
    timeout: 0,
});

async function loadImageFromData(data) {
    let img_blob_source = URL.createObjectURL(data);
    return new Promise(r => { 
        let i = new Image();
        i.onload = (() => r(i));
        i.src = img_blob_source;
    });
}

async function loadPatternForTick(tick) {
    let pattern = null;
    await axios_instance.get("/get_tick_pattern/" + tick).then(
        (response) => {pattern = response.data},
        (error) => { /*TODO handle error*/ }
    )
    return pattern;
}

async function loadImage(url) {
    let img_blob_source;
    await axios_instance.get(url, {responseType: 'blob'}).then(
        (result) => {
            img_blob_source = URL.createObjectURL(result.data);
        },
        (error) => {}
    );
    return new Promise(r => { 
        let i = new Image();
        i.onload = (() => r(i));
        i.onerror = (() => r());
        i.src = img_blob_source;
    });
}

async function uploadAnnotation(package_name, image_name, hand_side, blob) {
    const formData = new FormData();
    formData.append('file', blob, image_name);
    formData.set("hand_side", hand_side);
    //TODO save_image_err -> save_image
    await axios_instance.post(package_name +'/save_image/', formData).then(
        (response) => { cache_controller.removeLocalImage(package_name, image_name) },
        (error) => { cache_controller.saveImageLocally(package_name, image_name, hand_side, blob) }
    )
}

async function submitPackage(package_name, package_id) {
    let db = new LocalImageDB();
    let local_images = await db.getImages(package_name);
    if (local_images){
        await Promise.all(local_images.map((image, idx) => {
            return uploadAnnotation(package_name, image.img_name, image.hand_side, image.blob);
        }));
    }
    await axios_instance.post('/submit_package/', {"package_id": package_id}).then(
        (response) => {  },
        (error) => { /*TODO handle error*/ }
    )
}

export {axios_instance as requestHandler, loadImage, loadImageFromData, uploadAnnotation, submitPackage, loadPatternForTick};