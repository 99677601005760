import React from 'react';
import './Annotator.css';
import './SideBar.css';
import DrawingGui from './DrawingGui';
import { requestHandler } from './util/RequestHandler'
import { withRouter } from "react-router";
import {connect} from "react-redux";
import {annotatorSetSelectedImage, doGetPackage} from "./action/annotator";

let classNames = require('classnames');

class Annotator extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getPackage()
  }

  render() {
    const {error, isLoaded, images, selected_img} = this.props;
    const {setSelectedImage} = this.props;
    if (error) {
      return <div>Error: {error.get("message")}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    }
    const images_list = images.map((image, idx) => {
      let classes = classNames({
        "list-group-item": true,
        "text-truncate": true,
        "active" : idx === selected_img
      });
      return (
        <li key={idx} className={classes} onClick={() => setSelectedImage(idx)}> {image.get("name")}
        </li>
      );
    });
    return (
      <div className="container-fluid">
        <div className="row p-4 flex-xl-nowrap">
          <div className="col-2">
          Select image
          <div className="sidebar-sticky">
              <ul className="list-group nav felx-column">
                  {images_list}
              </ul>
          </div>

          </div>
          <DrawingGui/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    error: state.annotator.get("error"),
    isLoaded: state.annotator.get("isLoaded"),
    images: state.annotator.get("images"),
    selected_img: state.annotator.get("selected_img")
  }
}

function mapDispatchToProps(dispatch, props) {
  let package_name = props.match.params.package_name
  return {
    getPackage: () => {
      dispatch(doGetPackage(package_name))
    },
    setSelectedImage: (selected_img) => {
      dispatch(annotatorSetSelectedImage(selected_img))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Annotator));
