import React from 'react';
import { requestHandler, submitPackage} from './util/RequestHandler'
import {
    Link
  } from "react-router-dom";
import {connect} from "react-redux";
import {doGetPackages, doSubmitPackage, packageSelectorSetFilter} from "./action/packageSelector";
let classNames = require('classnames');

class PackageSelector extends React.Component {

  constructor(props) {
    super(props);
  }

  submitPackage(package_obj) {
    let confirmed = window.confirm("Are you sure you want to submit?");
    if (!confirmed){
      return;
    }
    this.props.submitPackage(package_obj)
  }

  componentDidMount() {
    this.props.getPackages()
  }

  select(p){
      console.log(p)
  }

  render() {
    const {error, isLoaded, packages, filter} = this.props;
    if (error) {
      return <div>Error: {error.get("message")}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    }
    const packages_list = packages.map((p, idx) => {
      let packageStatus = p.get("status")
      let classes = classNames({
          "col-md-4": true,
          "d-none": (filter === "active" && (packageStatus === "submitted" || packageStatus === "approved")) ||
                    (filter === "finished"  && (packageStatus === "queued" || packageStatus === "in_progress"))
      });

      let uploaded_percent = Math.round(100 * (p.get("annotated_count") / p.get("image_count")));
      let unuploaded_percent = Math.round(100 * (p.get("unuploaded_count") / p.get("image_count")));

      return (
        <div key={p.get("name")} className={classes}>
        <div className="card mb-4 shadow-sm">
            <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: Thumbnail"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"/><text x="50%" y="50%" fill="#eceeef" dy=".3em">{p.get("name").slice(0,7)}</text></svg>
            <div className="card-body">
            <p className="card-text">Annotated: {p.get("annotated_count") + p.get("unuploaded_count")}/{p.get("image_count")}</p>
            <div className="progress">
              <div className="progress-bar bg-success" role="progressbar" style={{width: uploaded_percent + "%"}} aria-valuenow={uploaded_percent} aria-valuemin="0" aria-valuemax="100">{uploaded_percent}%</div>
              <div className="progress-bar" role="progressbar" style={{width: unuploaded_percent + "%"}} aria-valuenow={unuploaded_percent} aria-valuemin="0" aria-valuemax="100">{unuploaded_percent}%</div>
            </div>
            <p className="card-text">{p.get("name")}</p>
            <div className="d-flex justify-content-between align-items-center">
                <div className="btn-group" hidden={(p.get("status") === "submitted" || p.get("status") === "approved")}>
                <button type="button" className="btn btn-sm btn-outline-success" onClick={() => {this.submitPackage(p)}}>Submit</button>
                <Link to={"/annotator/" + p.get("name")}>
                    <button type="button" className="btn btn-sm btn-outline-primary">Annotate</button>
                </Link>
                </div>
                <small className="text-muted">{p.get("status")}</small>
            </div>
            </div>
        </div>
        </div>
      );
    });
    return (
    <main role="main">
        <section className="jumbotron text-center">
            <div className="container">
            <h1>Select a Package</h1>
            <p className="lead text-muted">Please select a package below to annotate or submit a package that you have finished annotating!</p>
            <p>
                <button type="button" className="btn btn-primary my-2" onClick={() => {this.props.setFilter("active")}}>Active Packages</button>
                <button type="button" className="btn btn-secondary my-2" onClick={() => {this.props.setFilter("finished")}}>Finished Packages</button>
            </p>
            </div>
        </section>
        <div className="album py-5 bg-light">
            <div className="container">
                <div className="row">
                    {packages_list}
                </div>
            </div>
        </div>

      </main>
    )
  }
}

function mapStateToProps(state, props) {
    return {
        error: state.packageSelector.get("error"),
        isLoaded: state.packageSelector.get("isLoaded"),
        packages: state.packageSelector.get("packages"),
        filter: state.packageSelector.get("filter")
    };
}

function mapDispatchToProps(dispatch, props) {
    return {
        getPackages: () => {
            dispatch(doGetPackages())
        },
        setFilter: (filter) => {
            dispatch(packageSelectorSetFilter(filter))
        },
        submitPackage: (p) => {
            dispatch(doSubmitPackage(p))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageSelector);
