import {loadImage} from "../util/RequestHandler";
import cache_controller from "../util/CacheController";
import {
    DRAWING_CANVAS_UPDATE_ANNOTATION_IMAGE,
    DRAWING_CANVAS_UPDATE_LUT_IMAGE,
    DRAWING_CANVAS_UPDATE_MASK_IMAGE,
    DRAWING_CANVAS_UPDATE_PATHS
} from "./index";

function drawingCanvasUpdatePaths(mask_path, lut_path, annotation_path) {
    return {
        type: DRAWING_CANVAS_UPDATE_PATHS,
        mask_path,
        lut_path,
        annotation_path
    }
}

function drawingCanvasUpdateMaskImage(mask_path, mask_img) {
    return {
        type: DRAWING_CANVAS_UPDATE_MASK_IMAGE,
        mask_path,
        mask_img
    }
}

function drawingCanvasUpdateLutImage(lut_path, lut_img) {
    return {
        type: DRAWING_CANVAS_UPDATE_LUT_IMAGE,
        lut_path,
        lut_img
    }
}

function drawingCanvasUpdateAnnotationImage(annotation_path, annotation_img) {
    return {
        type: DRAWING_CANVAS_UPDATE_ANNOTATION_IMAGE,
        annotation_path,
        annotation_img
    }
}

export function doChangeImage(package_name, mask, lut, annotation) {
    return (dispatch) => {
        let mask_path = package_name + "/" + mask;
        let lut_path = package_name + "/" + lut;
        let annotation_path = package_name + "/" + annotation;
        dispatch(drawingCanvasUpdatePaths(mask_path, lut_path, annotation_path));

        loadImage("get_image/" + mask_path).then(mask_img => {
            dispatch(drawingCanvasUpdateMaskImage(mask_path, mask_img));
        })

        loadImage("get_image/" + lut_path).then(lut_img => {
            dispatch(drawingCanvasUpdateLutImage(lut_path, lut_img));
        })

        cache_controller.getAnnotationImage(package_name, annotation).then(annotation_img => {
            dispatch(drawingCanvasUpdateAnnotationImage(annotation_path, annotation_img))
        })
    }
}